<template>
  <div :class="`${prefixCls}-person`" id="aochenEdu">
    <Separate title="教育培训" desc="EDUCATION TRAINING" class="wow animate__fadeInUp" data-wow-duration="1s" />
    <div
        :class="`${prefixCls}-person-content wow animate__fadeInUp`"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
    >
      <div class="dynamics-card" v-for="item in eduList" :key="item.id" @click="handleClick(item)">
        <img :src="item.pic" alt="">
        <div class="desc-layout">
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.simpleContent }}</div>
          <div class="time-layout">
            <span class="time">{{ item.publishTime | formatDate }}</span>
            <span class="detail">
            详情
            <em class="el-icon-right" />
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
import { siteNewsPage } from '@/api/index'
import { validatenull } from '@/utils/validate'
import moment from 'moment/moment'
export default {
  name: 'Invite',

  filters: {
    formatDate (val) {
      if (validatenull(val)) return '--'
      return moment(val).format('yyyy-MM-DD')
    }
  },
  data () {
    return {
      prefixCls: this.$prefix + '-home',
      eduList: []
    }
  },
  mixins: [WowMixin],
  props: {
    introductionData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    Separate
  },
  created () {
    this.siteNewsPage()
  },
  methods: {
    handleClick (data) {
      this.$router.push({ path: '/detail', query: { id: data.id } })
    },
    siteNewsPage () {
      siteNewsPage({ channelId: '33' }, { channelId: 0 }).then(({ data }) => {
        this.eduList = data.data.rows
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -home-person;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: $content-width;
  padding-bottom: 50px;
  overflow: hidden;
  &-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
    .dynamics-card {
      display: flex;
      align-items: center;
      width: 100%;
      height: 210px;
      background: #f2f2f2;
      padding: 24px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all .3s;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        box-shadow: 0 0 16px 0 rgb(3 0 42 / 12%), 0 16px 24px 0 rgb(3 0 42 / 8%);
      }
      img {
        width: 216px;
        height: 162px;
        object-fit: cover;
        margin-right: 15px;
      }
      .desc-layout {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        overflow: hidden;
        .title {
          font-weight: bold;
          color: #333;
          margin-bottom: 25px;
          font-size: 18px;
        }
        .content {
          flex: 1;
          color: #666;
          font-size: 16px;
          text-align: left;
          @include ellipsis(4);
          font-family: arial !important;
          font-size: 16px !important;
          color: #191818 !important;
          line-height: 40px !important;
          font-weight: lighter !important;
        }
        .time-layout {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #666;
          font-size: 16px;
          font-family: arial !important;
          font-size: 16px !important;
          color: #191818 !important;
          line-height: 40px !important;
          font-weight: lighter !important;
        }
      }
    }
  }
}
</style>
